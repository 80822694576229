@import url('https://fonts.googleapis.com/css2?family=Lato&family=Merriweather:wght@600&display=swap');

.App {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
}
h1 {
  font-family: 'Merriweather', sans-serif;
  font-weight: 600;
  font-size: 4em;
  letter-spacing: .1em;
  margin: 3em 0 0 0;
}
body {
  font-family: 'Lato', sans-serif;
  color: #393E41;
}
p {
  font-size: 1.2em;
  margin: .5em;
}
.description {
  margin: 2em 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.socialLinks {
  display: flex;
  justify-content: space-between;
  font-size: 1.75em;
}
a {
  color: inherit;
  margin: 0 .75em;
}
.email:hover {
  color: #32936F;
}
.linkedin:hover {
  color: #3F88C5;
}
.github:hover{
  color: #AC80A0;
}
.medium:hover {
  color: #C3D350;
}

